<template>
  <div class="w1092">
    <div class="tab">
      <a @click="currentTab = 'usdt'" :class="{'on': currentTab == 'usdt'}">USDT (TRC20) 입금신청</a>
      <!--a @click="currentTab = 'ptp'" :class="{'on': currentTab == 'ptp'}">P2P로 구매하기</a-->
    </div>
    <template v-if="currentTab == 'usdt'">
      <div class="moneyinfo">
      <ul class="applistep">
          <li><img src="@/assets/img/icon_checks.svg" /></li>
          <li>
            "지갑주소호출" 버튼을 누르면 지갑주소가 호출됩니다. 해당 지갑주소로 원하시는 만큼의 USDT를 전송해주세요
            <div v-if="walletAddress" class="url">{{walletAddress}}</div>
          </li>
      </ul>
      <div class="applibtnWrap">
          <a class="applibtn" @click="getWallet">지갑 주소 호출</a>
      </div>
      </div>
      <div class="moneyinfoappli">
        <div class="appliinputwrap">
          <div>
            <h3>P2P로 입금신청</h3>
            <ul class="appliinput">
              <li>현재 환율</li>
              <li><span class="b w260">{{thousand(usdtToKRW)}}</span></li>
            </ul>
          </div>
          <div class="applibtns right">
            <a @click="goP2P">P2P 거래소 이동</a>
          </div>
        </div>
      </div>
    </template>
    <template v-if="currentTab == 'ptp'">
      <div class="moneyinfo">
      <ul class="applistep">
          <li><img src="@/assets/img/icon_checks.svg" /></li>
          <li>
            "지갑주소호출" 버튼을 누르면 지갑주소가 호출됩니다. 해당 지갑주소로 원하시는 만큼의 USDT를 전송해주세요
            <div v-if="walletAddress" class="url">{{walletAddress}}</div>
          </li>
      </ul>
      <div class="applibtnWrap">
          <a class="applibtn" @click="getWallet">지갑 주소 호출</a>
      </div>
      </div>
      <div class="moneyinfoappli">
        <div class="appliinputwrap">
          <div>
            <ul class="appliinput">
              <li>현재 환율</li>
              <li><span class="b w260">{{thousand(usdtToKRW)}}</span></li>
            </ul>
          </div>
          <div class="applibtns right">
            <a @click="goP2P">P2P 거래소 이동</a>
          </div>
        </div>
      </div>
    </template>

    <div class="board">
      <ul class="boardw first">
        <li class="">{{$t('front.board.applyDay')}}</li>
        <li class="">{{$t('front.board.processMoney')}}</li>
        <li class="">{{$t('front.board.processBonus')}}</li>
        <li class="">{{$t('front.board.processDay')}}</li>
        <li class="">{{$t('front.board.processState')}}</li>
      </ul>
      <template v-if="cashList.length > 0">
        <ul class="boardw" v-for="item in cashList" v-bind:key="item.cashIdx">
          <li class="">{{dateFormat(item.regDate)}}</li>
          <li class="">{{thousand(item.cashAmt)}}</li>
          <li class="">0</li>
          <li class="">{{dateFormat(item.updDate)}}</li>
          <li class=""><span :class="{'bl' : item.cashStatus !== '-1'}">{{computedCashStatus(item.cashStatus).text}}</span></li>
        </ul>
      </template>
      <template v-else>
        <ul class="boardw">
          <li>{{$t('front.common.notFoundList')}}</li>
        </ul>
      </template>
    </div>
    <div class="boardpage mt20 mb20">
      <pagination :pageNum="pageInfo.page"
                  :pageSize="pageInfo.count_per_list"
                  :totalCount="pageInfo.tatal_list_count"
                  @goToPage="onCashListSearch" />
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/ui/Pagination'
import { mapState } from 'vuex'
import { getUSDTExchangeURL, getUSDTWallet } from '@/api/usdt'
import { thousand } from '../../../libs/utils'
export default {
  name: 'Charge',
  computed: {
    ...mapState([
      'userData'
    ])
  },
  components: {
    Pagination
  },
  watch: {
  },
  async created () {
    this.usdtToKRW = await this.getUSDT()
  },
  data () {
    return {
      currentTab: 'usdt',
      cashType: 'in',
      cashList: [],
      type: 'apply',
      urlcall: false,
      walletAddress: '',
      usdtToKRW: 0
    }
  },
  methods: {
    thousand,
    getWallet () {
      getUSDTWallet({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.walletAddress = result.data.userWalletAddress
        } else {
          this.onAlert('warning', result.resultMessage)
        }
      })
    },
    goP2P () {
      getUSDTExchangeURL({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          window.open(result.data.exchangeUrl, '_blank')
        } else {
          this.onAlert('warning', result.resultMessage)
        }
      })
    }
  }
}
</script>
<style scoped>
.tab {display: flex;align-items: center;gap: 15px;margin-bottom: 20px;}
.tab a {width: 180px;
    height: 45px;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    font-size: 14px;
    font-weight: 300;
    color: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    border: 1px solid transparent;
    background-image: linear-gradient(to bottom, #6a6b6f, #404450), linear-gradient(to bottom, #b0772a, #ddb763 16%, #f4d780 25%, #f4d780 46%, #fbf9d8 65%, #f4d780 75%, #b0772a);
    background-origin: border-box;
    background-clip: content-box, border-box;
    display: flex;
    align-items: center;
    justify-content: center;}
.tab a:hover, .tab a.on {background: #ceb790;}
.applistep {height: 50px;align-items: center;}
.url {font-size: 20px;font-weight: bold;margin-top: 10px;}
.applibtns a {width: 235px !important;height: 68px !important;}
.appliinputwrap h3 {font-weight: bold;font-size: 18px;}
</style>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
